// custom typefaces
import '@fontsource-variable/montserrat'
import '@fontsource/merriweather'
// normalize CSS across browsers
import 'normalize.css'
// custom CSS styles
import './src/style.css'
// Highlighting for code blocks
import 'prism-themes/themes/prism-material-oceanic.css'

import colors from './src/lib/colors'
import { chooseRandom } from './src/lib/utils'

document.documentElement.style.setProperty(
  '--color-primary',
  chooseRandom(colors)
)
